<template>
  <a-spin tip="加载中..." :spinning="isSpinning">
    <a-form :form="form">
      <a-form-item
        label="公司全称"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input placeholder="请输入公司全称" v-decorator="['companyName']" />
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { addOrUpdateCompany } from "@/api/company";
export default {
  name: "TaskForm",
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      isSpinning: false,
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              companyName: values.companyName,
            };
            this.isSpinning = true;
            addOrUpdateCompany(formData)
              .then((res) => {
                this.isSpinning = false;
                setTimeout(() => {
                  this.$router.push(
                    {
                      path: `/company/${res.data.id}/info`,
                    },
                    1000
                  );
                });
                resolve(res);
              })
              .finally(() => {
                this.isSpinning = false;
              });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
