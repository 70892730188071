import request from "@/utils/request"

const followedCompanyApi = {
    GetFollowedCompanyList: '/followed-company/list',
    AddFollowedCompany: '/followed-company/add',
    UpdateFollowedCompany: '/followed-company/edit',
    DeleteFollowedCompany: '/followed-company/delete'
}

export function getFollowedCompanyList () {
    return request({
        url: followedCompanyApi.GetFollowedCompanyList,
        method: 'get'
    })
}

export function addFollowedCompany (parameter) {
    return request({
        url: followedCompanyApi.AddFollowedCompany,
        method: 'post',
        data: parameter
    })
}

export function updateFollowedCompany (parameter) {
    return request({
        url: followedCompanyApi.UpdateFollowedCompany,
        method: 'post',
        data: parameter
    })
}

export function deleteFollowedCompany (parameter) {
    return request({
        url: followedCompanyApi.DeleteFollowedCompany + `/${parameter.id}`,
        method: 'post',
    })
}
