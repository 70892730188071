<template>
  <div class="company-list">
    <div class="action-area">
      <a-button
        type="primary"
        icon="plus"
        @click="handleAdd"
        v-if="hasAction('add-or-update-company')"
        >新增或更新公司</a-button
      >
      <a-input-search
        v-model="params.inputStr"
        class="input-bar"
        placeholder="请输入公司名称"
        @search="fetchData"
      />
    </div>
    <a-table
      :columns="table.columns"
      :data-source="params.companyList"
      :loading="table.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    >
    </a-table>
  </div>
</template>

<script>
import { getCompanyList } from "@/api/company";
import { addFollowedCompany } from "@/api/followed-company";
import CompanyForm from "./components/CompanyForm";

export default {
  name: "CompanyList",
  data() {
    return {
      params: {
        inputStr: "",
        companyList: [],
      },
      ui: {
        adding: false,
      },
      table: {
        loading: false,
        columns: [
          {
            title: "公司名称",
            dataIndex: "name",
            width: "16em",
            customRender: (text, row) => {
              return (
                <a
                  onClick={() => {
                    this.$router.push({ path: `/company/${row.id}/info` });
                  }}
                >
                  {text}
                </a>
              );
            },
          },
          {
            title: "注册地址",
            width: "20em",
            dataIndex: "registerAddr",
          },
        ],
      },
    };
  },
  methods: {
    async fetchData(keyword) {
      if (!this.validate(keyword)) return;
      this.table.loading = true;
      getCompanyList({
        keyword,
      })
        .then((res) => {
          this.params.companyList = res.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.table.loading = false;
          }, 500);
        });
    },
    handleAdd() {
      this.$dialog(
        CompanyForm,
        {
          on: {
            ok: () => {},
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增或更新公司信息",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    validate(keyword) {
      if (!keyword) {
        this.$message.warning("请输入公司信息！");
        return false;
      }
      return true;
    },
    setStar(number, record) {
      let formData = {
        companyId: record.id,
        star: 1,
      };
      addFollowedCompany(formData).then(() => {
        this.$message.success("已关注");
        // User.getUserSearchCompanyHistory().then((res) => {
        //   this.params.companyList = res;
        // });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company-list {
  background-color: white;
  padding: 20px;
  .action-area {
    display: flex;
    justify-content: space-between;
    .input-bar {
      width: 350px;
      margin-bottom: 10px;
    }
  }
}
</style>
